@media screen and (max-width: 900px) {
  .goals {
    padding: 50px 16px;
  }
}

.goals {
  background: #ecf5ed;
  padding: 50px 15.625%;

  &__item {
    flex-direction: column;
    gap: 36px;
    &__header {
      align-items: center;
      gap: 22px;
    }
    &__image {
      background: #34a853;
      border-radius: 10px;
      padding: 11px;
    }
    &__heading {
      font-style: normal !important;
      font-weight: 600 !important;
      font-size: 32px !important;
      line-height: 40px !important;
      color: #000000 !important;
      text-transform: uppercase !important;
    }
    &__text {
      font-style: normal !important;
      font-weight: 500 !important;
      font-size: 20px !important;
      line-height: 25px !important;
      color: #000000 !important;
    }
  }
}
