@mixin respond($breakpoint) {
  @if $breakpoint == large {
    @media (max-width: 90.625em) {
      // 1450px
      @content;
    }
  }
  @if $breakpoint == medium {
    @media (max-width: 62.5em) {
      // 1000px
      @content;
    }
  }
  @if $breakpoint == small {
    @media (max-width: 56.25em) {
      // 900px
      @content;
    }
  }
  @if $breakpoint == smaller {
    @media (max-width: 51.875em) {
      // 830px
      @content;
    }
  }

  @if $breakpoint == tablet {
    @media (max-width: 40.625em) {
      // 650px
      @content;
    }
  }

  @if $breakpoint == mobile {
    @media (max-width: 37.5em) {
      // 600px
      @content;
    }
  }

  @if $breakpoint == mini-mobile {
    @media (max-width: 31.875em) {
      // 500px
      @content;
    }
  } @else {
    @media (max-width: $breakpoint) {
      //customize
      @content;
    }
  }
}
