@import "../mixins/responsiveness.scss";

.offer {
  margin-bottom: 282px;
  @include respond(medium) {
    margin-bottom: 100px;
  }
  &__heading {
    text-align: left !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 32px !important;
    line-height: 40px !important;
    color: #000000 !important;
    margin-bottom: 11px !important;
  }

  &__content {
    font-style: normal !important;
    font-weight: 300 !important;
    font-size: 20px !important;
    line-height: 25px !important;
    color: #000000 !important;
    margin-bottom: 32px !important;
  }

  &__feature {
    position: relative;
    &:not(:last-child)::after {
      content: "";
      height: 31.5px;
      width: 1px;
      border: 1px solid #000000;
      margin-left: 20px;
    }
    &__image {
      background: #34a853 !important;
      border-radius: 10px !important;
      padding: 9px 11px !important;
    }
    &__heading {
      font-style: normal !important;
      font-weight: 600 !important;
      font-size: 24px !important;
      line-height: 30px !important;

      color: #000000 !important;
    }
    &__text {
      font-style: normal !important;
      font-weight: 300 !important;
      font-size: 18px !important;
      line-height: 23px !important;

      color: #000000 !important;
    }
  }
}
