@import "../mixins/responsiveness.scss";

.form {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.25);
  margin: 0;
  margin-inline: 0 !important;
  border-left: none;
  align-items: flex-start !important;
  justify-content: flex-start;
  padding: 40px 2.225% 0 3.82%;
  gap: 60px;
  @include respond(medium) {
    border: none;
    padding: 20px 8px 0 8px;
  }

  .phone-input {
    padding-left: 26% !important;
  }

  p,
  label {
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 15px !important;
    line-height: 19px !important;
    color: #878787 !important;
  }

  &__heading {
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 24px !important;
    line-height: 30px !important;
    color: #34a853 !important;
  }

  &__inputs {
    column-gap: 57px;
    row-gap: 50px;
    flex-direction: column;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }

  &__checkboxes {
    p {
      margin-bottom: 20px;
    }
    &__wrap {
      gap: 5px;
      span.chakra-checkbox__control {
        border: 1px solid #34a853;
        border-radius: 2px;
        &[data-checked] {
          background: #34a853;
        }
      }
      span.chakra-checkbox__label {
        margin-left: 11px;
      }
    }
  }

  &__action {
    margin-top: 50px !important;
    align-self: flex-end !important;
    background: #34a853 !important;
    border-radius: 5px !important;
    padding: 16px 50px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 15px !important;
    line-height: 19px !important;
    color: #ffffff !important;
    @include respond(medium) {
      align-self: center !important;
    }
  }
}
