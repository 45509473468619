@import "../mixins/responsiveness.scss";

.copyright {
  padding: 20px 8vw;
  align-items: center;
  justify-content: space-between;
  @include respond(medium) {
    padding: 20px 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
  }
  &__text {
    gap: 10px;
    align-items: center;
    p {
      font-style: normal !important;
      font-weight: 300 !important;
      font-size: 15px !important;
      line-height: 22px !important;
      text-align: center !important;
      letter-spacing: -0.408px !important;
      color: #000000 !important;
    }
  }
}
