@import "../mixins/responsiveness.scss";

.action {
  margin: 63px 8.9%;
  @include respond(medium) {
    margin: 50px 8px;
  }
  &__main {
    background: #34a853;
    border-radius: 20px;
    padding: 17px 22px 19px 16px;
    @include respond(medium) {
      padding: 18px 8px;
    }
  }
  &__inner {
    border: 5px solid #ffffff;
    border-radius: 15px;
    align-items: center;
    padding: 0 6.91% 0 4.5%;
    gap: 15%;
    @include respond(medium) {
      padding: 0 8px;
    }
  }
  &__content {
    align-items: flex-start;
  }
  &__heading {
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 40px !important;
    line-height: 50px !important;
    color: #ffffff !important;
    margin-bottom: 22px !important;
    @include respond(medium) {
      font-size: 30px !important;
      text-align: center;
    }
  }

  &__text {
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 20px !important;
    line-height: 25px !important;
    margin-bottom: 122px !important;
    color: #ffffff !important;
    @include respond(medium) {
      text-align: center;
      margin-bottom: 50px !important;
    }
  }
  &__buttons {
    flex-direction: row !important;
    align-items: center !important;
    gap: 16px;
    @include respond(medium) {
      margin-bottom: 10px !important;
    }
  }
  &__button {
    gap: 10px !important;
    background: #ffffff !important;
    border-radius: 10px !important;
    margin-top: 0 !important;
    padding: 20px 37px !important;
    height: unset !important;
    min-width: unset !important;
    @include respond(medium) {
      padding: 10px 9px !important;
    }
    span {
      font-style: normal !important;
      font-weight: 500 !important;
      font-size: 15px !important;
      line-height: 19px !important;
      color: #000000 !important;
    }
  }
  &__image {
    transform: scaleY(1.082);
  }
}
