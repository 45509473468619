@import "../mixins/responsiveness.scss";

.footer {
  background: #ecf5ed;
  padding: 32px 8.75% 120px 8.75%;
  &__wrap {
    grid-template-columns: 2fr 1fr 1fr 4fr;
    grid-template-rows: repeat(1, 1fr);
    align-items: flex-start;
    @include respond(medium) {
      flex-direction: column;
    }
  }
  &__image {
    width: 100%;
  }
  &__links {
    gap: 37px;
    margin-top: 22px;
    align-items: flex-start !important;
    @include respond(medium) {
      gap: 22px;
    }
  }
  &__link {
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 20px !important;
    line-height: 25px !important;
    text-align: center !important;
    color: #000000 !important;
  }
  &__cta {
    gap: 20px;
    justify-content: flex-start !important;
    @include respond(medium) {
      width: 100% !important;
      margin-top: 3em !important;
      align-items: flex-start !important;
      &__input {
        width: 100% !important;
      }
    }
  }
  &__heading {
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 24px !important;
    line-height: 30px !important;
    text-align: center !important;
    color: #000000 !important;
  }
}
