@import "../mixins/responsiveness.scss";

.hero {
  &__heading {
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 65px !important;
    line-height: 105.52% !important;
    text-align: center !important;
    margin-bottom: 26px !important;
    color: #000000 !important;
    @include respond(medium) {
      font-size: 40px !important;
      line-height: 1 !important;
    }
  }

  &__content {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 20px !important;
    line-height: 25px !important;
    text-align: center !important;
    margin-bottom: 32px !important;
    color: #535353 !important;
  }

  &__input {
    height: 63px !important;
    width: 63.75% !important;
    @include respond(medium) {
      width: 90% !important;
    }
    input {
      background: #ffffff;
      box-shadow: 6px 15px 50px rgba(0, 0, 0, 0.14);
      border-radius: 10px;
      &:placeholder-shown {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 22px;

        letter-spacing: -0.408px;

        color: #b7b7b7;
      }
    }
  }

  &__action {
    background: #34a853 !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 20px !important;
    line-height: 22px !important;
    /* or 110% */

    text-align: center;
    letter-spacing: -0.408px;

    color: #ffffff;

    padding: 12px 20px;
  }

  &__join {
    width: unset !important;
    button {
      min-width: unset;
      background: #34a853;
      border-radius: 10px;
      width: 11.04vw !important;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 22px;
      /* or 110% */

      text-align: center;
      letter-spacing: -0.408px;

      color: #ffffff;

      @include respond(medium) {
        width: 13vw !important;
      }
    }
  }

  &__ending {
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 36px !important;
    line-height: 45px !important;
    color: #000000 !important;
    margin-bottom: 89px !important;
    @include respond(medium) {
      margin-top: 30px !important;
      text-align: center !important;
      margin-bottom: 89px !important;
      font-size: 20px !important;
      line-height: 1 !important;
    }
  }
}
