@import "../mixins/responsiveness.scss";

.header {
  padding: 22px 8.9% 53px 8.9%;
  @include respond(medium) {
    padding: 22px 16px 53px 16px;
  }
  a {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 20px !important;
    line-height: 22px !important;

    text-align: center !important;
    letter-spacing: -0.408px !important;

    color: #242426 !important;
  }

  &__action {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 20px !important;
    line-height: 22px !important;

    text-align: center !important;
    letter-spacing: -0.408px !important;

    color: #ffffff !important;
  }

  &__menu {
    display: block;
    @media screen and (min-width: 30em) {
      display: none;
    }
    &__button {
      display: block !important;
      @media screen and (min-width: 30em) {
        display: none !important;
      }
      svg {
        transform: scale(2);
      }
    }
  }
}
