@media screen and (max-width: 700px) {
  .page-404 .header__search-form {
    display: none;
  }
}
.pagenotfound {
  @media screen and (max-width: 1024px) {
    flex-direction: column;
    text-align: center;
  }
}
