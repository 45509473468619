.otp-input {
  position: relative;
  width: 100%;
}
.otp-input input {
  width: 100px !important;
  height: 75px !important;
  padding: 0 !important;
  text-align: center;
  font-size: 1.9rem !important;
  width: inherit;
  font-weight: bolder;
}

@media screen and (max-width: 900px) {
  .wrap {
    flex-wrap: wrap;
  }
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
  opacity: 0;
  visibility: hidden;
}

.modal-backdrop.show {
  visibility: visible;
  opacity: 0.5;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  font-size: 20px;
}
